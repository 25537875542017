import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	account: null,
	status: "idle",
	club: null,
	por: null,
	admin: null,
};

const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		setAccount: (state, action) => {
			state.account = action.payload;
		},
		setClub: (state, action) => {
			state.club = action.payload;
		},
		setPor: (state, action) => {
			state.por = action.payload;
		},
		setAdmin: (state, action) => {
			state.admin = action.payload;
		},
		setStatus: (state, action) => {
			state.status = action.payload;
		},
	},
});

export const { setAccount, setClub, setPor, setAdmin, setStatus } =
	accountSlice.actions;

export const selectAccount = (state) => state.account.account;
export const selectClub = (state) => state.account.club;
export const selectPor = (state) => state.account.por;

export default accountSlice.reducer;
