/* eslint-disable no-unused-vars */
import { lazy, Suspense } from "react";
import React, { useState, useEffect } from "react";

import {
	BrowserRouter as Router,
	Route,
	Redirect,
	Switch,
	useHistory,
} from "react-router-dom";
import Loading from "./components/Loading/Loading";
import axios from "axios";

import Navbar from "./components/NavbarNew/Navbar";

//import Footer from './components/Footer/Footer'
//import footer from './assets/images/footer.png'
import Typing1 from "./assets/home/scroll";
import { app } from "./fierbase";

import { withCookies, Cookies as cookies } from "react-cookie";

import { backend_url } from "./const";
import { useDispatch, useSelector } from "react-redux";
import {
	setAccount,
	setAdmin,
	setClub,
	setPor,
	setStatus,
} from "./functions/Slices/AccountSlice";
import ScanQR from "./pages/ScanQR/scan";
const ComedyRegistration = React.lazy(() =>
	import("./pages/ComedyRegistration")
);
// import { Departments } from "./pages/Departments/Departments";
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const footer = React.lazy(() => import("./assets/images/footer.png"));
const { Section } = React.lazy(() => import("./assets/home/scroll"));
const TechSenate = lazy(() => import("./components/Senates/TechSenate"));
const Departments = React.lazy(() => import("./pages/Departments/Departments"));
const Academic = React.lazy(() => import("./pages/academic/Academic"));
const About = React.lazy(() => import("./pages/About/About"));
const Contact = React.lazy(() => import("./pages/Contact/Contact"));
const Change = React.lazy(() => import("./pages/Change/Change"));
const Home = React.lazy(() => import("./pages/Home/Home"));
const Places = React.lazy(() => import("./pages/Places/Places"));
const FegComponent = React.lazy(() => import("./pages/Feg/FegComponent"));
const HelpAndAdvice = React.lazy(() =>
	import("./pages/HelpAndAdvice/HelpAndAdvice")
);
const ModalFgp = React.lazy(() => import("./pages/Feg/ModalFgp"));
const CultSenate = lazy(() => import("./components/Senates/CultSenate"));
const SportsSenate = React.lazy(() =>
	import("./components/Senates/SportsSenate")
);
const CampusFacilities = React.lazy(() =>
	import("./pages/CampusFacilities/CampusFacilities")
);
const Auditorium = React.lazy(() => import("./pages/Auditorium/Auditorium"));
const AcadBlock = React.lazy(() => import("./pages/Acad-block/AcadBlock"));
const Clubs = React.lazy(() => import("./pages/clubs/clubs"));
const Studentlife = React.lazy(() => import("./pages/Studentlife/Studentlife"));
const Senates = React.lazy(() => import("./pages/Senates/Senates"));
const Signin = React.lazy(() => import("./pages/signin/Signin"));
const FoodOutlets = React.lazy(() => import("./pages/FoodOutlets/FoodOutlets"));
const Fests = React.lazy(() => import("./pages/Fests/Fests"));
const Sac = React.lazy(() => import("./pages/Sac/Sac"));
const Error404 = React.lazy(() => import("./pages/Home/Error404"));
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const AtmosPage = React.lazy(() => import("./pages/Atmos"));
const AdminPage = React.lazy(() => import("./pages/FestAdmin"));
//const ScanQR = React.lazy(() => import("./pages/ScanQR/scan"));
const Protected = ({ isDashboard, loaded, children }) => {
	const history = useHistory();

	useEffect(() => {
		if (loaded) {
			if (!isDashboard) {
				history.push("/login");
			}
		}
	}, [loaded, isDashboard]);
	return loaded && children;
};

const App = () => {
	const [isDashboard, setIsDashboard] = useState(false);
	const [dashboardData, setdashboardData] = useState(null);
	const [loaded, setIsLoaded] = useState(false);
	const Cookies = new cookies();
	const token = Cookies.get("jwt");

	const account = useSelector((state) => state.account);
	const dispatch = useDispatch();

	const checkAdminAndPorStatus = () => {
		let token = Cookies.get("jwt");
		axios
			.get(backend_url + "/auth/checkAll", {
				headers: {
					Authorization: `Bearer ${token}`,
					"Access-Control-Allow-Origin": "*",
				},
				withCredentials: true,
			})
			.then((result) => {
				const data = {
					id: result.data.id,
					email: result.data.email,
					name: result.data.name,
					token: token,
				};
				dispatch(setAccount(data));
				setdashboardData(data);
				setIsDashboard(true);
				setIsLoaded(true);
				return result.data;
			})
			.then((data) => {
				if (data.isPor) {
					dispatch(setPor(true));
					dispatch(setClub(data.club));
				} else {
					dispatch(setPor(false));
				}
				if (data.isAdmin) {
					dispatch(setAdmin(true));
				} else {
					dispatch(setAdmin(false));
				}
			})
			.catch((error) => {
				dispatch(setPor(false));
				dispatch(setAdmin(false));
				setIsDashboard(false);
				setIsLoaded(true);
			})
			.finally(() => dispatch(setStatus("set")));
	};
	const handleGoogleSignin = () => {
		setIsLoaded(false);
		checkAdminAndPorStatus();
	};
	useEffect(() => {
		checkAdminAndPorStatus();
	}, []);
	return (
		<>
			<Router>
				{/* <Navbar isDashboard={isDashboard} />
        <div
          style={{ height: "10vh", width: "100%", background: "white" }}
        ></div> */}
				<main>
					<Suspense fallback={<Loading />}>
						<Switch>
							{" "}
							<Route exact path="/club">
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Clubs />
							</Route>
							<Route exact path="/feg">
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<FegComponent />
							</Route>
							<Route path="/about" exact>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<About />
							</Route>
							<Route path="/change" exact component={Change}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Change />
							</Route>
							<Route path="/places" exact component={Places}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Places />
							</Route>
							<Route path="/studentlife" exact component={Studentlife}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Studentlife />
							</Route>
							<Route path="/helpandadvice" exact component={HelpAndAdvice}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<HelpAndAdvice />
							</Route>
							<Route path="/contact" exact component={Contact}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Contact />
							</Route>
							<Route path="/academic" exact component={Academic}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Academic />
							</Route>
							<Route path="/fests" exact component={Fests}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Fests />
							</Route>
							<Route path="/techsenate" exact component={TechSenate}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<TechSenate />
							</Route>
							<Route path="/cultsenate" exact component={CultSenate}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<CultSenate />
							</Route>
							<Route path="/sportssenate" exact component={SportsSenate}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<SportsSenate />
							</Route>
							<Route path="/foodoutlets" exact component={FoodOutlets}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<FoodOutlets />
							</Route>
							<Route path="/sac" exact component={Sac}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Sac />
							</Route>
							<Route
								path="/campusfacilities"
								exact
								component={CampusFacilities}
							>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<CampusFacilities />
							</Route>
							<Route path="/about" exact>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Suspense fallback={<Loading />}>
									<About />
								</Suspense>
							</Route>
							<Route path="/change" exact component={Change}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Change />
							</Route>
							<Route path="/places" exact component={Places}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Places />
							</Route>
							<Route path="/studentlife" exact component={Studentlife}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Studentlife />
							</Route>
							<Route path="/helpandadvice" exact component={HelpAndAdvice}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<HelpAndAdvice />
							</Route>
							<Route path="/contact" exact component={Contact}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Contact />
							</Route>
							<Route path="/academic" exact component={Academic}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Academic />
							</Route>
							<Route path="/fests" exact component={Fests}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Fests />
							</Route>
							<Route path="/techsenate" exact component={TechSenate}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Suspense fallback={<Loading />}>
									<TechSenate />
								</Suspense>
							</Route>
							<Route path="/departments" exact component={Departments}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Departments />
							</Route>
							<Route path="/cultsenate" exact component={CultSenate}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<CultSenate />
							</Route>
							<Route path="/sportssenate" exact component={SportsSenate}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<SportsSenate />
							</Route>
							<Route path="/foodoutlets" exact component={FoodOutlets}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<FoodOutlets />
							</Route>
							<Route path="/sac" exact component={Sac}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Sac />
							</Route>
							<Route path="/auditorium" exact component={Auditorium}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<Auditorium />
							</Route>
							<Route path="/acadblock" exact component={AcadBlock}>
								<Navbar isDashboard={isDashboard} />
								<div
									style={{
										height: "10vh",
										width: "100%",
										background: "white",
									}}
								></div>
								<AcadBlock />
							</Route>
							{/* <Redirect to="/" /> */}
							<Route exact path="/" component={Home}>
								<Home isDashboard={isDashboard} />
							</Route>
							<Route exact path="/test" component={Typing1} />
							<Route path="/login" exact>
								<Signin handleGoogleSignin={handleGoogleSignin} />
							</Route>
							<Route path="/dashboard">
								{" "}
								<Protected loaded={loaded} isDashboard={isDashboard}>
									<Navbar isDashboard={isDashboard} />
									<Dashboard data={dashboardData} />
								</Protected>
							</Route>
							<Route path="/comedy">
								{" "}
								<Protected loaded={loaded} isDashboard={isDashboard}>
									<Navbar isDashboard={isDashboard} />
									<ComedyRegistration data={dashboardData} />
								</Protected>
							</Route>
							<Route path="/scan">
								{" "}
								<Protected loaded={loaded} isDashboard={isDashboard}>
									<Navbar isDashboard={isDashboard} />
									<ScanQR />
								</Protected>
							</Route>
							<Route path="/fgp">
								{" "}
								<Protected loaded={loaded} isDashboard={isDashboard}>
									<Navbar isDashboard={isDashboard} />

									<ModalFgp data={dashboardData} />
								</Protected>
							</Route>
							<Route path={"/"+process.env.REACT_APP_FEST_NAME.split(" ")[0]}>
								<Navbar isDashboard={isDashboard} />
								<AtmosPage />
							</Route>
							<Route path="/admin">
								<Protected loaded={loaded} isDashboard={isDashboard}>
									<Navbar isDashboard={isDashboard} />
									<AdminPage />
								</Protected>
							</Route>
							<Route component={Error404} />
						</Switch>
					</Suspense>
				</main>
			</Router>
		</>
	);
};

export default withCookies(App);
