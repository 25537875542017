import React, { useState } from 'react';
import {QrReader} from 'react-qr-reader';
import axios from 'axios';
import { backend_url } from '../../const'
import Cookies from 'js-cookie';
import { CircularProgress, Dialog } from '@mui/material';

function ScanQR() {
    const [data, setData] = useState('No result');
    const token = Cookies.get('jwt');
    const [qrscan, setQrscan] = useState(true);

    // const handleScan = (data) => {
    //     if (data) {
    //         //decode the data recieved from the QR code

    //         setResult(data);
    //         console.log(result);
    //     }
    // }

    // const handleError = (err) => {
    //     console.error(err);
    // }


    return (
        <>
          {qrscan && <QrReader

            key="environment"
            constraints={{ facingMode: 'environment' }}
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text);
                console.info(result?.text);
                axios.post(`${backend_url}/comedy/qrscan`, {
                    id: result?.text,
                    }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    })
                    .then((res) => {
                        console.log(res);
                        alert(res.data.message);
                        setQrscan(true);
                    })
                    .catch((err) => {
                        console.log(err);
                        alert(err.response.data.message);
                        setQrscan(true);
                    });
                console.log("hi");
                setQrscan(false);

              }
    
              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: '100%' }}
          />}
          {!qrscan && <Dialog open={!qrscan}
            
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}onClose={() => setQrscan(true)}>
            <CircularProgress />
          </Dialog>}
          <p>{data}</p>
        </>
      );
}

export default ScanQR;
